.join {
    display: flex;
    gap    : 10rem;
    padding: 0 2rem;
}

.left-j {
    color         : white;
    font-size     : 3rem;
    font-weight   : bold;
    text-transform: uppercase;
    position      : relative;
}

.left-j>hr {
    position     : absolute;
    border       : 2px solid var(--orange);
    width        : 10.5rem;
    border-radius: 20%;
    margin       : -10px 0;
}

.left-j>div {
    display: flex;
    gap    : 1rem;
}

.right-j {
    display        : flex;
    justify-content: center;
    align-items    : flex-end;
}



.email-container {
    display         : flex;
    gap             : 3rem;
    padding         : 1rem 2rem;
    background-color: gray;
}

.email-container>input {
    background-color: transparent;
    border          : none;
    outline         : none;
    color           : var(--lightgrayz);
}

::placeholder {
    color: var(lightgray);
}

.btn-join {
    background-color: var(--orange);
    color           : white;
}

@media screen and (max-width:768px) {
    .join {
        flex-direction: column;
        gap           : 1rem;
    }

    .left-j {
        flex-direction: column;
        font-size     : x-large;
    }

    .right-j {
        padding: 2rem;
    }
}